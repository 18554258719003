import React, { useState, useEffect, useRef, useCallback } from 'react'
import SbEditable from 'storyblok-react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { H4, Icon, Link } from '@system'
import { WistiaVideoDetailsPromise } from '@hooks/get-wistia-video-details'
import { loadWistiaScript } from '@helpers/load-wistia-script'
import renderBloks from '@renderBloks'
import get from 'lodash/get'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '480px',
    margin: '1rem 0',
    position: 'relative',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightLight,
    display: 'block',
    margin: '15px 0',
  },
  link: {
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    display: 'block',
  },
  thumbnailContainer: {
    maxHeight: '250px',
    borderRadius: '4px',
    position: 'relative',
  },
  playButton: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  thumbnail: {
    maxHeight: '250px',
    width: '100%',
    borderRadius: '4px',
  },
  time: {
    height: '20px',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightExtraBold,
    paddingTop: '6px',
    color: theme.palette.text.plant,
    bottom: '30px',
    left: '7px',
  },
  pill: {
    backgroundColor: theme.palette.text.plant,
    width: '59px',
    height: '25.94px',
    borderRadius: '47px',
    color: theme.palette.text.white,
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightExtraBold,
    textAlign: 'center',
    paddingTop: '2px',
    marginRight: '18px',
  },
  bottom: {
    display: 'flex',
  },
}))

const VideoCard = (props) => {
  const classes = useStyles()
  const mountedRef = useRef(true)
  const [details, setDetails] = useState()
  const { pillText, link, video, thumbnail, title, time } = props.blok
  const wistiaId = video.length > 0 ? video[0].wistiaId : null
  const [videoThumbnail, setVideoThumbnail] = useState(
    get(thumbnail, '[0].image')
  )
  const formatTime = (time) => {
    const formatted = Math.round(time)
    return `${formatted} MIN`
  }

  useEffect(() => {
    loadWistiaScript()
  }, [])

  const ManualCard = () => (
    <Box className={classes.container}>
      <Box className={classes.thumbnailContainer}>
        <Icon styles={classes.playButton}>VideoPlayCircle</Icon>
        <img
          src={videoThumbnail}
          alt="item thumbnail"
          className={classes.thumbnail}
        />
      </Box>
      <H4 className={classes.title}>{title || details?.name}</H4>
      <Box className={classes.bottom}>
        {!!pillText && <Box className={classes.pill}>{pillText}</Box>}
        <Box className={classes.time}>
          {formatTime(time || details?.duration / 60)}
        </Box>
      </Box>
    </Box>
  )

  const LinkWrapper = ({ children }) =>
    link?.cached_url ? (
      <Link link={link} linkStyle={classes.link}>
        {children}
      </Link>
    ) : (
      children
    )

  const fetchSpecificItem = useCallback(
    (wistiaId) => {
      return WistiaVideoDetailsPromise(wistiaId)
        .then((res) => res.json())
        .then((data) => {
          if (!mountedRef.current) return null
          if (!data) return null
          if (data.error) throw data.error
          setDetails(data)
          setVideoThumbnail((prev) => prev || data?.thumbnail?.url)
        })
        .catch((err) =>
          console.warn(
            err,
            'Could not fetch data from wistia. Ensure you own this video!'
          )
        )
    },
    [mountedRef]
  )

  useEffect(() => {
    if (wistiaId) fetchSpecificItem(wistiaId)
  }, [wistiaId, fetchSpecificItem])

  return (
    <SbEditable content={props.blok}>
      {link?.cached_url || !wistiaId ? (
        <LinkWrapper>
          <ManualCard />
        </LinkWrapper>
      ) : (
        renderBloks(video, { contentToHyperlink: ManualCard() })
      )}
    </SbEditable>
  )
}

export default VideoCard
